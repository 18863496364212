import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/layout/home'
  },
  {
    path: '/home',
    component: () => import('../views/Home/home'),
    meta: {
      title: '万年历'
    }
  },
  {
    path: '/test',
    component: () => import('../views/Home/test')
  },
  {
    path: '/prepareForWedding',
    component: () => import('../views/PrepareForWedding'),
    meta: {
      title: '备婚事项'
    }
  },
  {
    path: '/login',
    component: () => import('../views/Login')
  },
  {
    path: '/serviceHeartBreak',
    component: () => import('../views/ServiceHeartBreak')
  },
  {
    path: '/layout',
    component: () => import('../views/Layout'),
    children: [
      {
        path: 'home',
        component: () => import('../views/Home'),
        meta: {
          title: '主页'
        }
      },
      {
        path: 'news',
        component: () => import('../views/News'),
        meta: {
          title: '新闻'
        }
      },
      {
        path: 'chat',
        component: () => import('../views/Personal/Chat'),
        meta: {
          title: '记账'
        }
      },
      {
        path: 'bill',
        component: () => import('../views/Personal/Bill'),
        meta: {
          title: '账单'
        }
      },
      {
        path: 'balance',
        component: () => import('../views/Personal/Bill/balance.vue'),
        meta: {
          title: '资产'
        }
      },
      {
        path: 'balancelog',
        component: () => import('../views/Personal/Bill/log.vue'),
        meta: {
          title: '资产变化'
        }
      },
      {
        path: 'healthindex',
        component: () => import('../views/Personal/Health/category'),
        meta: {
          title: '健康'
        }
      },
      {
        path: 'health',
        component: () => import('../views/Personal/Health')
      },
      {
        path: 'weight',
        component: () => import('../views/Personal/Health/weight')
      },
      {
        path: 'healthlist',
        component: () => import('../views/Personal/Health/list')
      },
      {
        path: 'countdownday',
        component: () => import('../views/Personal/CountDownDay'),
        meta: {
          title: '倒数日'
        }
      },
      {
        path: 'chooice',
        component: () => import('../views/Personal/Chooice'),
        meta: {
          title: '今天吃什么'
        }
      },
      {
        path: 'chooice/settings',
        component: () => import('../views/Personal/Chooice/settings')
      },
      {
        path: 'punchcard',
        component: () => import('../views/Personal/PunchCard'),
        meta: {
          title: '考勤'
        }
      },
      {
        path: 'fund',
        component: () => import('../views/Personal/Fund')
      },
      {
        path: 'punchtask',
        component: () => import('../views/Personal/PunchTask'),
        meta: {
          title: '习惯打卡'
        }
      },
      {
        path: 'edittask',
        component: () => import('../views/Personal/PunchTask/edittask')
      },
      {
        path: 'read',
        component: () => import('../views/Read'),
        meta: {
          title: '书架'
        }
      },
      {
        path: 'readdetail',
        component: () => import('../views/Read/detail'),
        meta: {
          title: '阅读'
        }
      },
      {
        path: 'addbook',
        component: () => import('../views/Read/editbook')
      },
      {
        path: 'addlog',
        component: () => import('../views/Read/addlog')
      },
      {
        path: 'tomato',
        component: () => import('../views/Personal/Tomato/tomato')
      },
      {
        path: 'billlist',
        component: () => import('../views/Personal/Bill/list.vue'),
        meta: {
          title: '账单汇总'
        }
      },
      {
        path: 'issues',
        component: () => import('../views/Personal/Bill/issues.vue'),
        meta: {
          title: '账单详情'
        }
      },
      {
        path: 'task',
        component: () => import('../views/Personal/Task/index.vue'),
        meta: {
          title: '待办'
        }
      },
      {
        path: 'weektask',
        component: () => import('../views/Personal/Task/week.vue'),
        meta: {
          title: '周视图'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 添加全局前置守卫
router.beforeEach((to, from, next) => {
  /* 路由发生变化时修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  const token = localStorage.getItem('token')

  // 如果要去的不是登录页，且没有token，则重定向到登录页
  if (to.path !== '/login' && to.path !== '/layout/home' && to.path !== '/prepareForWedding' && !token) {
    next('/login')
  } else {
    next()
  }
})
export default router
