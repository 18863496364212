// API 接口统一管理模块
const API = {
  // 账单相关接口
  bill: {
    // 获取账单汇总
    get_total_by_date: '/keep_accounts/get_total_by_date',
    get_total_by_issues: '/keep_accounts/get_total_by_issues',
    get_list_by_issues: '/keep_accounts/getlist_by_issue'
  },
  // 备婚事项相关接口
  wedding: {
    // 获取备婚事项列表
    get_wedding_list: '/v2/my/get_prepare_for_wedding',
    // 新增事项
    add_wedding: '/v2/my/add_prepare_for_wedding',
    // 删除事项
    delete_wedding: '/v2/my/del_prepare_for_wedding',
    // 更新状态
    update_wedding_status: '/v2/my/update_prepare_for_wedding'
  },
  // 心跳服务相关接口
  heartbeat: {
    // 获取心跳服务列表
    get_heartbeat_list: '/v2/my/get_service_heart_break_list',
    // 删除
    delete_heartbeat: '/v2/my/del_service_heart_break'
  },
  // 待办相关接口
  task: {
    // 同步事项
    sync_task: '/v2/my/sync_task',
    // 获取事项列表
    get_task_list: '/v2/my/get_my_task_list',
    // 更新事项状态
    update_task_status: '/v2/my/update_task_status',
    // 删除事项
    del_task: '/v2/my/del_task'
  }
}

export default API
